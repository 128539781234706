import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VehicleManufactureAutocompleteComponent } from './vehicle-manufacture-autocomplete.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormUtilsModule } from '@fc-core/form-utils/form-utils.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormFieldComponent } from '@fc-shared/ui/input/core/form-field/form-field.component';
import { LabelComponent } from '@fc-shared/ui/input/core/label/label.component';
import { InputDirective } from '@fc-shared/ui/input/core/input.directive';
import { ErrorComponent } from '@fc-core/form-utils/form-error/error.component';

@NgModule({
  declarations: [VehicleManufactureAutocompleteComponent],
  exports: [VehicleManufactureAutocompleteComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    FormUtilsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    FormFieldComponent,
    LabelComponent,
    InputDirective,
    ErrorComponent,
  ],
})
export class VehicleManufactureAutocompleteModule {}
