<fc-vehicle-detail
  [vehicle]="selectedVehicle$ | async"
  [vehicleStats]="vehicleStats$ | async"
  [vehicleGroups]="vehicleGroups$ | async"
  [vehicleType]="vehicleType$ | async"
  [vehicleOperators]="assignedOperators$ | async"
  [scheduleList]="scheduleList$ | async"
  [vehiclePhotos]="vehiclePhotos$ | async"
  [currentCameraInfo]="currentCameraInfo$ | async"
  [cameraStatus]="cameraState$ | async"
  (toggleGroups)="onVehicleGroupToggle($event)"
></fc-vehicle-detail>
