<div class="recordings-timeline">
  @if (durations().length === 0) {
    <div class="no-recordings">
      <fc-empty-state
        icon="assets/icons/no-clips-icon.svg"
        title="No recordings"
        subtitle="You haven't recorded videos yet"
      ></fc-empty-state>
    </div>
  } @else {
    <div class="header">
      <div class="header-legend gap-24">
        <div class="title h3 color-grey-900">Timeline</div>
        <div class="legend gap-12">
          <div class="legend-item gap-4">
            <fc-icon
              [size]="12"
              color="error500"
              icon="icon-dvr_record"
            ></fc-icon>
            <span class="color-grey-500 label-1">Recording</span>
          </div>
          <div class="legend-item gap-4">
            <fc-icon
              [size]="12"
              color="warning500"
              icon="icon-mdi_clock-remove-outline"
            ></fc-icon>
            <span class="color-grey-500 label-1">Overwriting</span>
          </div>
        </div>
      </div>

      <div class="empty-dates-toggle">
        <fc-toggle
          [formControl]="toggleEmptyDatesControl"
          [label]="'Hide empty dates'"
        ></fc-toggle>
      </div>
    </div>

    <div #timeline class="timeline m-t-16">
      @for (
        duration of durationsTimeline | keyvalue | sortTimelineByDate;
        track index;
        let last = $last;
        let index = $index;
        let first = $first
      ) {
        <div
          #timelineItem
          [style.width.px]="sizeOf24Hours()"
          class="timeline-item"
          [class.selected]="selectedIndex === index"
          [class.first]="first"
          [class.last]="last"
        >
          <div class="date text-sm">
            <fc-icon
              color="warning500"
              [size]="12"
              icon="icon-mdi_clock-remove-outline"
              *ngIf="first"
            ></fc-icon>
            <span>{{ duration.key }}</span>
            <fc-icon
              color="error500"
              [size]="12"
              icon="icon-dvr_record"
              *ngIf="last"
            ></fc-icon>
          </div>

          <div
            class="timeblock-hover-button"
            (click)="selectTimelineItem(index, duration.key)"
          ></div>

          <div class="time-block-row">
            <div class="line-row"></div>

            @for (item of duration.value; track item) {
              <div
                (click)="selectTimelineItem(index, duration.key)"
                [style.left.px]="item.position"
                [style.width.px]="item.width"
                class="time-blocks"
              ></div>
            }
          </div>
        </div>
      }
    </div>
    <ng-template
      (backdropClick)="selectedIndex = null"
      [cdkConnectedOverlayBackdropClass]="'transparent'"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayOpen]="selectedIndex !== null"
      [cdkConnectedOverlayOrigin]="tooltipTarget"
      cdkConnectedOverlay
    >
      <div class="timeline-tooltip">
        <div class="arrow"></div>
        <div class="timeline-tooltip-header gap-8 align-center">
          <fc-icon [size]="20" icon="icon-save"></fc-icon>
          <div class="title h3">{{ selectedDate }}</div>
        </div>

        @if (selectedTimeLineItem?.total) {
          <div class="timeline-tooltip-body m-t-12">
            <div class="total align-center gap-4">
              <fc-icon [size]="16" icon="icon-schedule"></fc-icon>
              <div class="total-time flex-row align-center gap-4">
                <div class="body color-grey-500">Total Time:</div>
                <fc-tag backgroundColor="success100" textColor="success500">{{
                  selectedTimeLineItem?.total
                }}</fc-tag>
              </div>
            </div>
          </div>

          <div class="tooltip-footer">
            <fc-text-button size="small" (onClick)="requestVideo()">
              <fc-icon color="primary600" icon="icon-video_call"></fc-icon>
              <span>Request video</span>
            </fc-text-button>

            <fc-tonal-button size="small" (onClick)="startPlayback()">
              <fc-icon
                color="primary600"
                icon="icon-play_circle_outline"
              ></fc-icon>
              <span>Playback</span>
            </fc-tonal-button>
          </div>
        }
      </div>
    </ng-template>
  }
</div>
