import { Pipe, PipeTransform } from '@angular/core';
import { ClipInterface } from '@fc-vehicles/models/camera/clip.interface';

@Pipe({
  name: 'clipsListFilter',
  standalone: true,
})
export class ClipsListFilterPipe implements PipeTransform {
  transform(
    clips: ClipInterface[],
    filter: 'all' | 'requested' | 'alerts',
  ): ClipInterface[] {
    if (filter === 'alerts') {
      return clips.filter((clip) => clip.title.toLowerCase().includes('alert'));
    }
    if (filter === 'requested') {
      return clips.filter(
        (clip) => !clip.title.toLowerCase().includes('alert'),
      );
    }
    if (filter === 'all') {
      return clips;
    }
  }
}
