<fc-dialog-header
  [customCloseFunction]="closeVehicleForm.bind(this)"
  [title]="vehicle ? 'Update vehicle' : 'New vehicle'"
></fc-dialog-header>
<fc-dialog-content>
  <form
    (keydown.enter)="submitForm()"
    [formGroup]="vehicleForm"
    class="core-vehicle-form"
  >
    <div class="vehicle-photo-container">
      <fc-drop-area
        #dropArea
        (dropped)="onImageDropped($event)"
        [class.hidden]="photoSrc"
        icon="icon-vehicles"
      ></fc-drop-area>
      <fc-vehicle-photo
        (addNewPhoto)="dropArea.openFileBrowser()"
        (photoDeleted)="deletePhoto()"
        *ngIf="photoSrc"
        [photoSrc]="photoSrc"
      ></fc-vehicle-photo>
    </div>
    <ng-container *ngIf="!nonTracker">
      <fc-part-select-with-dropdown
        (partSelectedEmitter)="partSelected($event)"
        [error]="hardwareError"
        [selectedPart]="selectedPart"
        [update]="!!vehicle"
        class="part-selector"
      ></fc-part-select-with-dropdown>
      <fc-vehicle-part-selector
        (click)="selectDvrPart()"
        [disable]="noHardware"
        [error]="cameraError"
        [selectedPart]="selectedCamera"
        [title]="'Add DVR'"
        class="part-selector"
      ></fc-vehicle-part-selector>
    </ng-container>
    <div class="form-row">
      <fc-form-field class="m-b-20">
        <fc-label>Identifier</fc-label>
        <input fcInput formControlName="identifier" placeholder="Identifier" />
        <fc-error>
          <fc-form-group-error
            [formGroup]="vehicleForm"
            [path]="'identifier'"
          ></fc-form-group-error>
        </fc-error>
      </fc-form-field>
    </div>
    <div class="form-row">
      <fc-select
        formControlName="type"
        label="Vehicle type"
        placeholder="Vehicle type"
        searchPlaceholder="Vehicle type search"
      >
        @for (type of vehicleTypes; track type.id) {
          <fc-option [value]="type.id">
            <img
              #typeImage
              (error)="typeImage.style.display = 'none'"
              [src]="'/assets/icons/vehicle-types/' + type.slug + '.svg'"
              alt="type-icon"
            />
            <span>{{ type.name }}</span>
          </fc-option>
        }
      </fc-select>
      <fc-form-group-error
        [formGroup]="vehicleForm"
        path="type"
      ></fc-form-group-error>
    </div>
    <div class="form-row form-inline three m-b-20">
      <fc-vehicle-manufacture-autocomplete
        data-cy="vehicle-manufacture"
        formControlName="manufacturer"
      >
        <fc-error>
          <fc-form-group-error
            [formGroup]="vehicleForm"
            [path]="'manufacturer'"
          ></fc-form-group-error>
        </fc-error>
      </fc-vehicle-manufacture-autocomplete>
      <fc-form-field>
        <fc-label>Model</fc-label>
        <input
          data-cy="model-input"
          fcInput
          formControlName="model"
          placeholder="Model"
        />
        <fc-error>
          <fc-form-group-error
            [formGroup]="vehicleForm"
            [path]="'model'"
          ></fc-form-group-error>
        </fc-error>
      </fc-form-field>
      <fc-form-field>
        <fc-label>Year</fc-label>
        <input
          [max]="currentYear"
          data-cy="year-input"
          fcInput
          formControlName="year"
          placeholder="Year"
          type="number"
        />
        <fc-error>
          <fc-form-group-error
            [customErrors]="{
              pattern:
                'Please enter a valid year between 1900 and the current year',
              max: 'The year entered cannot be greater than current year',
            }"
            [formGroup]="vehicleForm"
            [path]="'year'"
          ></fc-form-group-error>
        </fc-error>
      </fc-form-field>
    </div>
    @if (!vehicle) {
      <div class="form-inline form-row double with-hardware-row m-b-20">
        <fc-form-field>
          <fc-label>Odometer</fc-label>
          <input
            data-cy="odometer-input"
            formControlName="realOdometer"
            fcInput
            min="0"
            placeholder="Odometer"
            type="number"
          />
          <fc-error>
            <fc-form-group-error
              [formGroup]="vehicleForm"
              [path]="'realOdometer'"
            ></fc-form-group-error>
          </fc-error>
        </fc-form-field>
        <fc-form-field>
          <fc-label>Engine Hours</fc-label>
          <input
            data-cy="engine-hours-input"
            formControlName="realEngineHours"
            fcInput
            min="0"
            placeholder="Engine Hours"
            type="number"
          />
          <fc-error>
            <fc-form-group-error
              [formGroup]="vehicleForm"
              [path]="'realEngineHours'"
            ></fc-form-group-error>
          </fc-error>
        </fc-form-field>
      </div>
    } @else {
      <div class="form-row">
        <fc-form-field class="m-b-20">
          <fc-label>Vin number</fc-label>
          <input
            data-cy="vin-number"
            formControlName="vinOverride"
            fcInput
            placeholder="Vin number"
          />
          <fc-error>
            <fc-form-group-error
              [formGroup]="vehicleForm"
              [path]="'vinOverride'"
            ></fc-form-group-error>
          </fc-error>
        </fc-form-field>
      </div>
    }
    <div class="form-row">
      <fc-select
        (onSelect)="vehicleForm.controls.groups.markAsTouched()"
        [hasError]="groupError"
        [multiple]="true"
        formControlName="groups"
        label="Vehicle group"
        placeholder="Choose vehicle groups*"
        searchPlaceholder="Vehicle group search"
      >
        @for (group of groups; track group.id) {
          <fc-option [value]="group.id">
            <div
              [style.background]="group.color"
              class="additional-group-color"
            ></div>
            <span>{{ group.name }}</span>
          </fc-option>
        }
      </fc-select>
    </div>
  </form>
</fc-dialog-content>
<fc-dialog-footer
  (primaryClicked)="submitForm()"
  [cancelFn]="closeVehicleForm.bind(this)"
  [disabled]="isDisabled"
  [loading]="loading"
  [primaryText]="vehicle ? 'Save' : 'Create'"
></fc-dialog-footer>
