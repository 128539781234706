import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { getSelectedGroup } from '../../../store';

@Component({
  selector: 'fc-vehicle-groups-page',
  templateUrl: './vehicle-groups-page.component.html',
  styleUrls: ['./vehicle-groups-page.component.scss'],
  standalone: false,
})
export class VehicleGroupsPageComponent {
  store = inject(Store);
  selectedGroup = this.store.selectSignal(getSelectedGroup);
}
