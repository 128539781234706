import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Hardware, HardwareApiService } from '@fc-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddDeviceInputComponent } from './add-device-input.component';

@Component({
  selector: 'fc-add-odb',
  template: `
    <fc-dialog-header
      title="Add ODB"
      [showCloseButton]="false"
    ></fc-dialog-header>
    <fc-dialog-content>
      <div class="add-device-container">
        <div class="add-device-content">
          <img
            class="add-device-image"
            src="assets/images/device-types/odb.png"
            alt="odb"
          />
          <div class="add-device-instruction">
            <div class="title h3">Simply connect your Tracker</div>
            <div class="text">
              Connect vehicle by adding Tracker Number below and we will
              automatically load other details. Use one of methods below:
            </div>
            <div class="instruction-list">
              <div class="instruction-item">
                <div class="number">1</div>
                <div class="instruction-text">Add tracker number manually</div>
              </div>
              <div class="instruction-item">
                <div class="number">2</div>
                <div class="instruction-text">
                  Scan Bar code using mobile phone
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-device-inputs">
          <fc-add-device-input
            #hardwareInput
            [requestFunction]="getHardwareList"
            [verifiedFunction]="verifyHardware"
            [serialLength]="9"
            [title]="'Tracker number'"
          ></fc-add-device-input>
        </div>
      </div>
    </fc-dialog-content>
    <fc-dialog-footer
      [cancelFn]="onBack.bind(this)"
      [cancelText]="'Back'"
      [disabled]="!valid"
      (primaryClicked)="onAddDevice()"
      primaryText="Add"
    ></fc-dialog-footer>
  `,
  styleUrls: ['./add-device.scss'],
  standalone: false,
})
export class AddOdbComponent implements AfterViewInit {
  @ViewChild('hardwareInput', { static: false })
  hardwareInput: AddDeviceInputComponent<Hardware>;
  @Input() selectedHardware: Hardware;
  @Input() vehicleId: number;
  @Output() back = new EventEmitter();
  @Output() addDevice = new EventEmitter();

  constructor(private hardwareService: HardwareApiService) {}

  ngAfterViewInit() {
    this.hardwareInput?.itemsControl.setValue(this.selectedHardware);
  }

  get valid(): boolean {
    return this.hardwareInput?.itemsControl.valid;
  }

  onAddDevice() {
    this.addDevice.emit({
      hardware: this.hardwareInput?.itemsControl.value,
    });
  }

  getHardwareList = (serial: string): Observable<Hardware[]> => {
    return this.hardwareService
      .search(serial, false)
      .pipe(
        map((hardware) =>
          hardware.filter((item) => !item.device.harnessRequired),
        ),
      );
  };

  verifyHardware = (serial: string): Observable<Hardware> => {
    return this.hardwareService.validateHardware(serial, this.vehicleId);
  };

  onBack() {
    this.back.emit();
  }
}
