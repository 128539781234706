import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  input,
  output,
  signal,
  Signal,
  viewChild,
  viewChildren,
  WritableSignal,
} from '@angular/core';
import { Duration, DvrDuration } from '@fc-vehicles/components';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { MomentFormatPipeModule } from '@fc-shared/pipes/moment-format-pipe/moment-format-pipe.module';
import moment from 'moment-timezone';
import { KeyValuePipe, NgIf } from '@angular/common';
import { TextButtonComponent } from '@fc-shared/ui/buttons/text-button.component';
import { TonalButtonComponent } from '@fc-shared/ui/buttons/tonal-button.component';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { isMobile } from '@fc-shared/utils/is-mobile';
import { SortTimelineByDatePipe } from '@fc-vehicles/components/recordings-timeline/sort-timeline-by-date.pipe';
import { EmptyStateModule } from '@fc-shared/ui/empty-state/empty-state.module';
import { TagComponent } from '@fc-shared/ui/tags/tag.component';
import { ToggleComponent } from '@fc-shared/ui/toggle/toggle.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DurationTimeline } from '@fc-vehicles/components/recordings-timeline/duration.timeline';

@Component({
  selector: 'fc-recordings-timeline',
  imports: [
    IconComponent,
    MomentFormatPipeModule,
    KeyValuePipe,
    TextButtonComponent,
    TonalButtonComponent,
    CdkConnectedOverlay,
    SortTimelineByDatePipe,
    EmptyStateModule,
    NgIf,
    TagComponent,
    ToggleComponent,
    ReactiveFormsModule,
  ],
  templateUrl: `./recordings-timeline.component.html`,
  styleUrls: ['./recordings-timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecordingsTimelineComponent implements AfterViewInit {
  cdr = inject(ChangeDetectorRef);
  timelineItems = viewChildren<ElementRef>('timelineItem');
  timeline: Signal<ElementRef> = viewChild('timeline');
  sizeOf24Hours: WritableSignal<number> = signal(80);
  durations = input.required<DvrDuration[]>();
  tooltipTarget: ElementRef = null;
  selectedIndex = null;
  selectedDate = null;
  durationsTimeline: DurationTimeline = {};
  emptyDates = [];
  onPlayback = output<string>();
  onRequestVideo = output<string>();
  toggleEmptyDatesControl = new FormControl<boolean>(false);

  get selectedTimeLineItem(): { date: string; total: string } {
    const selectedDurationTimeline = this.durationsTimeline[this.selectedDate];
    if (!selectedDurationTimeline || selectedDurationTimeline.length === 0)
      return;
    const totalSeconds = selectedDurationTimeline.reduce(
      (acc, item) => acc + item.totalTime,
      0,
    );
    const duration = moment.duration(totalSeconds, 'seconds');
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) - hours * 60;

    return {
      date: moment(selectedDurationTimeline[0].duration?.lower).format(
        'MMMM DD, YYYY',
      ),
      total: `${hours}h ${minutes}m`,
    };
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.initTimeline();
  }

  ngAfterViewInit() {
    this.initTimeline();
    this.toggleEmptyDatesControl.valueChanges.subscribe(() =>
      this.formatDurationsTimeline(),
    );
  }

  private initTimeline(): void {
    this.getSizeOf24Hours();
    this.formatDurationsTimeline();
    this.getEmptyDates();
    this.cdr.detectChanges();
  }

  selectTimelineItem(index: number, date: string): void {
    if (isMobile()) return;
    this.selectedIndex = index;
    this.selectedDate = date;
    this.tooltipTarget = this.timelineItems()[index]?.nativeElement;
  }

  getSizeOf24Hours() {
    const uniqueDatesMap = new Map<string, boolean>();
    this.durations().forEach((duration) => {
      const date = moment(duration.duration.upper).format('MMMM DD');
      uniqueDatesMap.set(date, true);
    });

    const uniqueDatesCount = uniqueDatesMap.size;
    const fullWidth = this.timeline().nativeElement.offsetWidth;
    this.sizeOf24Hours.set(fullWidth / uniqueDatesCount);
  }

  formatDurationsTimeline(): void {
    const dates = [...this.durations()];

    this.durationsTimeline = dates
      .sort((a, b) => moment(a.duration.upper).diff(moment(b.duration.upper)))
      .reduce((acc, duration) => {
        const date = moment(duration.duration.upper).format('MMMM DD');
        acc[date] = acc[date] || [];
        acc[date].push({
          duration: duration.duration,
          totalTime: this.getTotalTime(duration.duration),
          width: this.getDurationWidth(duration.duration),
          position: this.getDurationPosition(duration.duration),
        });
        return acc;
      }, {});

    if (!this.toggleEmptyDatesControl.value)
      this.emptyDates.map((date) => (this.durationsTimeline[date] = []));
  }

  getEmptyDates(): void {
    const dates = Object.keys(this.durationsTimeline);
    const startDate = dates[0];
    const endDate = dates[dates.length - 1];
    const allDates = this.getAllDates(startDate, endDate);
    this.emptyDates = allDates.filter((date) => !this.durationsTimeline[date]);
    if (this.emptyDates.length) {
      this.toggleEmptyDatesControl.setValue(true);
    }
  }

  private getAllDates(startDate: string, endDate: string): string[] {
    const allDates = [];
    const start = moment(startDate);
    const end = moment(endDate);
    let currentDate = start;
    while (currentDate.isSameOrBefore(end)) {
      allDates.push(currentDate.format('MMMM DD'));
      currentDate = currentDate.add(1, 'days');
    }
    return allDates;
  }

  getDurationWidth(duration: Duration): number {
    const lower = moment(duration.lower);
    const upper = moment(duration.upper);
    const totalDurationSeconds = upper.diff(lower, 'seconds');
    const proportionInDay = totalDurationSeconds / 86400;
    return proportionInDay * this.sizeOf24Hours();
  }

  getTotalTime(duration: Duration): number {
    const lower = moment(duration.lower);
    const upper = moment(duration.upper);
    return upper.diff(lower, 'seconds');
  }

  getDurationPosition(duration: Duration): number {
    const lower = moment(duration.lower);
    const proportionInDay =
      lower.diff(moment(lower).startOf('day'), 'seconds') / 86400;
    return proportionInDay * this.sizeOf24Hours();
  }

  requestVideo() {
    const selectedTimeline = this.durationsTimeline[this.selectedDate];
    this.onRequestVideo.emit(
      moment(selectedTimeline[0].duration.lower).toISOString(),
    );
    this.selectedIndex = null;
    this.selectedDate = null;
  }

  startPlayback() {
    const selectedTimeline = this.durationsTimeline[this.selectedDate];
    this.onPlayback.emit(
      moment(selectedTimeline[0].duration.lower).toISOString(),
    );
    this.selectedIndex = null;
    this.selectedDate = null;
  }
}
