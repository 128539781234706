import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from '@fc-vehicles/components';

@Pipe({
  name: 'sortTimelineByDate',
  standalone: true,
})
export class SortTimelineByDatePipe implements PipeTransform {
  transform(
    value: {
      key: string;
      value: {
        duration: Duration;
        totalTime: number;
        width: number;
        position: number;
      }[];
    }[],
  ): {
    key: string;
    value: {
      duration: Duration;
      totalTime: number;
      width: number;
      position: number;
    }[];
  }[] {
    return value.sort(
      (a, b) => new Date(a.key).getTime() - new Date(b.key).getTime(),
    );
  }
}
