import { Component, OnDestroy, OnInit } from '@angular/core';
import { Html5Qrcode } from 'html5-qrcode';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'fc-barcode-scanner',
  template: `
    <div class="barcode-scanner-container">
      <div id="barcode-scanner"></div>
      <div class="loading" *ngIf="loading">
        <mat-spinner></mat-spinner>
      </div>
      <div class="options">
        <div class="close-button">
          <button mat-button (click)="closeScanner()">Close</button>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      #barcode-scanner {
        height: 100%;
        width: 100%;
      }

      .options {
        width: 100%;
        bottom: 0;
        background: white;
        display: flex;
        flex-direction: column;

        .close-button {
          height: 100px;

          button {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .barcode-scanner-container {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.8);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .loading {
        @extend .barcode-scanner-container;
        position: absolute;
        background: rgba(0, 0, 0, 0.5);
        z-index: 100;
      }

      .camera-list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 12px;
      }
    `,
  ],
  standalone: false,
})
export class BarcodeScannerComponent implements OnInit, OnDestroy {
  code$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  close$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loading = true;
  private scanner: Html5Qrcode;

  async ngOnInit(): Promise<void> {
    this.scanner = new Html5Qrcode('barcode-scanner');
    this.startCameraScanner();
  }

  async startCameraScanner(cameraId?: string): Promise<void> {
    await this.scanner
      .start(
        cameraId ? cameraId : { facingMode: 'environment' },
        {
          fps: 10,
          qrbox: 250,
          aspectRatio: 9 / 16,
          disableFlip: true,
        },
        (decodedText, decodedResult) => {
          this.code$.next(decodedResult.decodedText);
        },
        (errorMessage) => {},
      )
      .catch((err) => {});
    this.loading = false;
  }

  closeScanner() {
    this.close$.next(true);
    try {
      this.scanner.stop();
      this.scanner.clear();
    } catch (err) {
      console.log(err);
    }
  }

  ngOnDestroy(): void {
    this.closeScanner();
  }
}
