import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[fcPinchZoom]',
  standalone: true,
})
export class PinchZoomDirective {
  private scale = 1;
  private initialDistance = 0;

  constructor(private el: ElementRef) {}

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    if (event.touches.length === 2) {
      event.preventDefault();
      this.initialDistance = this.getDistance(
        event.touches[0],
        event.touches[1],
      );
    }
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(event: TouchEvent) {
    if (event.touches.length === 2) {
      event.preventDefault();

      const currentDistance = this.getDistance(
        event.touches[0],
        event.touches[1],
      );
      const scaleFactor = currentDistance / this.initialDistance;

      let newScale = this.scale * scaleFactor;

      // Limit zoom range
      newScale = Math.min(Math.max(1, newScale), 4);

      this.el.nativeElement.style.transform = `scale(${newScale})`;
    }
  }

  @HostListener('touchend')
  onTouchEnd() {
    this.scale =
      parseFloat(
        this.el.nativeElement.style.transform
          .replace('scale(', '')
          .replace(')', ''),
      ) || 1;
  }

  private getDistance(touch1: Touch, touch2: Touch): number {
    const dx = touch1.pageX - touch2.pageX;
    const dy = touch1.pageY - touch2.pageY;
    return Math.sqrt(dx * dx + dy * dy);
  }
}
