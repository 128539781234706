import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoSourceSwitcherComponent } from './video-source-switcher.component';
import { MatIconModule } from '@angular/material/icon';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';

@NgModule({
  declarations: [VideoSourceSwitcherComponent],
  exports: [VideoSourceSwitcherComponent, VideoSourceSwitcherComponent],
  imports: [CommonModule, MatIconModule, IconComponent],
})
export class VideoSourceSwitcherModule {}
