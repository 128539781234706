import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsEmptyPipe } from './is-empty.pipe';
import { IsNotEmptyPipe } from './is-not-empty.pipe';

@NgModule({
  declarations: [IsEmptyPipe, IsNotEmptyPipe],
  imports: [CommonModule],
  exports: [IsEmptyPipe, IsNotEmptyPipe],
})
export class IsEmptyPipeModule {}
