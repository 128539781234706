import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VehicleManufacture } from '../../../models/vehicle.manufacture';

@Injectable({
  providedIn: 'root',
})
export class VehicleManufactureService {
  constructor(private http: HttpClient) {}

  getVehicleManufacture(search?: string): Observable<VehicleManufacture[]> {
    return this.http
      .get<{
        results: VehicleManufacture[];
      }>(environment.apiUrl + `api/equipment/manufacturer/?search=${search}`)
      .pipe(map((data: { results: VehicleManufacture[] }) => data.results));
  }
}
