import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { inject } from '@angular/core';
import { VehicleApiService } from '@fc-vehicles/service/vehicle-api.service';
import { Store } from '@ngrx/store';
import { VehicleDvrVideoService } from '@fc-vehicles/service/vehicale-dvr-video.serevice';
import { getSelectedVehicle, selectRouterParam } from '@fc-store/selectors';
import { map, switchMap } from 'rxjs/operators';
import { Vehicle } from '@fc-core';
import { of } from 'rxjs';

export const hasCameraGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const vehicleService = inject(VehicleApiService);
  const store = inject(Store);
  const router = inject(Router);
  const dvrVideoService = inject(VehicleDvrVideoService);
  return store.select(getSelectedVehicle).pipe(
    switchMap((vehicle: Vehicle) => {
      if (vehicle) {
        return of(true);
      } else {
        const id = +store.selectSignal(selectRouterParam('id'))();
        return vehicleService.loadVehicle(id, ['camera']).pipe(
          switchMap((vehicle: Vehicle) => {
            if (vehicle.camera === null) return;
            // todo add activation status to vehicle options
            return dvrVideoService.getCameraInfo(vehicle.camera).pipe(
              map((cameraInfo) => {
                return cameraInfo.activationStatus === 'active';
              }),
            );
          }),
        );
      }
    }),
    map((state: boolean) => {
      if (!state) {
        router.navigate([
          '/vehicles/main/vehicles',
          store.selectSignal(selectRouterParam('id'))(),
        ]);
      }
      return state;
    }),
  );
};
