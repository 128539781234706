import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { PhotoUploadCardModule } from '../photo-upload-card/photo-upload-card.module';
import { UploadModule } from '../upload.module';

import { UploadedListComponent } from './uploaded-list.component';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';

@NgModule({
  imports: [
    CommonModule,
    PhotoUploadCardModule,
    UploadModule,
    MatIconModule,
    IconComponent,
  ],
  exports: [UploadedListComponent],
  declarations: [UploadedListComponent],
  providers: [],
})
export class UploadedListModule {}
