import { VehicleOptions } from '@fc-core/models/vehicle/vehicle-options';

export const vehicleLoadOptions: VehicleOptions[] = [
  'vin',
  'hardware',
  'harness',
  'identifier',
  'manufacturer',
  'model',
  'year',
  'photo',
  'groups',
  'type',
  'power_source',
  'created',
  'status',
  'last_reading',
  'last_state',
  'specifications',
  'pending_maintenance_count',
  'pending_dtc_count',
  'camera',
  'mil',
  'operators',
  'non_tracker',
  'install_type',
  'setstatus',
];
