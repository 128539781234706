import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MediaModel } from '@fc-vehicles/service/media-model';

@Component({
  selector: 'fc-media-view-pdf',
  template: `
    <pdf-viewer
      [src]="fileUrl"
      [render-text]="true"
      [original-size]="false"
      (after-load-complete)="fileLoaded.emit(true)"
    ></pdf-viewer>
  `,
  styles: [
    `
      @use 'var' as *;

      :host {
        display: flex;
        height: 80vh;
        width: calc(100vw - 400px);
        @media (max-width: $sm-size) {
          width: 100vw;
        }
      }

      pdf-viewer {
        width: 100%;
        height: 100%;
      }
    `,
  ],
  standalone: false,
})
export class MediaViewPdfComponent {
  @Input({ alias: 'file', required: true }) set setFile(file: MediaModel) {
    this.fileUrl = file.original;
  }

  @Output() fileLoaded = new EventEmitter<boolean>();
  fileUrl: string;
}
